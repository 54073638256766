<template>
  <div class="min-h-screen flex flex-col justify-center py-6 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="../assets/img/durban.jpg" alt="Durban" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign up
      </h2>
    </div>


      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form @submit.prevent="submitForm" class="space-y-6">
            <div>
              <label for="username" class="block text-sm font-medium text-gray-700">
                Username
              </label>
              <div class="mt-1">
                <input id="username" name="username" v-model="username" type="text" autocomplete="username" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            </div>

            <div>
              <label for="password2" class="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <div class="mt-1">
                <input id="password2" name="password2" type="password" v-model="password2" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            </div>

            <div class="notification text-red-500" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
            </div>

            <div>
              <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign up
              </button>
            </div>

            <div class="mt-6">
              <div class="relative">
                <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300" />
                </div>
                <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                Or <router-link class="underline text-blue-900" to="/login">click here</router-link> to log in.
              </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { createToast } from 'mosha-vue-toastify';

export default {
  name: "Signup",
  data () {
    return {
      username: '',
      password: '',
      password2: '',
      errors: []
    }
  },
  mounted() {
    document.title = 'Sign up | Durban'
  },
  methods: {
    submitForm() {
      this.errors = []
      if (this.username === '') {
        this.errors.push('The username is missing')
      }
      if (this.password === '') {
        this.errors.push('The password is too short')
      }
      if (this.password !== this.password2) {
        this.errors.push('The passwords doesn\'t match')
      }
      if (!this.errors.length) {
        const formData = {
          username: this.username,
          password: this.password
        }
        axios
            .post("/api/v1/users/", formData)
            .then(response => {
              createToast({
                title: 'Info',
                description: 'Account created, please log in!'},
                  {
                    timeout: 5000,
                    position: 'top-right',
                    type: 'success',
                  })
              this.$router.push('/login')
            })
            .catch(error => {
              if (error.response) {
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                }
                console.log(JSON.stringify(error.response.data))
              } else if (error.message) {
                this.errors.push('Something went wrong. Please try again')

                console.log(JSON.stringify(error))
              }
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
